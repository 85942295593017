import dayjs from "dayjs";
var calendar = require("dayjs/plugin/calendar");
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
dayjs.extend(utc); dayjs.extend(timezone);dayjs.extend(calendar);
const obj = {
  currencyFormat: (number = "") => {
    // return new Intl.NumberFormat('en-IN', {}).format(number)
    const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
    const formatted = Number(number).toLocaleString("en", options);
    return formatted;
  },
  dateFormat: (date, timeZone) => {
    var convertedDate = new Date(date).toLocaleString(undefined, {
      timeZone:  "Asia/Dhaka",//timeZone ? timeZone :
    });

    return convertedDate.toString();
  },
  msgDateFormat: (date, timeZone) => {
    var convertedDate = new Date(date).toLocaleDateString(undefined, {
      timeZone: timeZone ? timeZone : "Asia/Kolkata",
    });

    return convertedDate.toString();
  },
  matchDateTime: (date, timeZone) => {
    
    return dayjs(date).calendar(null, {
      sameDay: 'h:mm A', // The same day ( Today at 2:30 AM )
      nextDay: '[Tomorrow] h:mm A', // The next day ( Tomorrow at 2:30 AM ) [Tomorrow at] h:mm A
      nextWeek: 'DD/MM/YYYY h:mm A', // The next week ( Sunday at 2:30 AM )
      lastDay: '[Yesterday ] h:mm A', // The day before ( Yesterday at 2:30 AM )
      lastWeek: '[Last] dddd h:mm A', // Last week ( Last Monday at 2:30 AM )
      sameElse: 'DD/MM/YYYY h:mm A' // Everything else ( 17/10/2011 )
    })
  },
  truncateDecimals :(num, digits)  =>{
    var numS = num?.toString(),
        decPos = numS?.indexOf('.'),
        substrLength = decPos == -1 ? numS?.length : 1 + decPos + digits,
        trimmedResult = numS?.substr(0, substrLength),
        finalResult = isNaN(trimmedResult) ? 0 : trimmedResult;

    return parseFloat(finalResult);
    
}
};

export default obj;
