import React, { useState,useContext } from "react";
import { Container, Col, Row, Table, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { apiGet, apiPost, apiPut } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import helpers from "../utils/helpers";
import { useForm } from "react-hook-form";
import { toast } from "wc-toast";
import { isEmpty } from "lodash";
import AuthContext from "../context/AuthContext";

const PlayerBalance = () => {
  let { user } = useContext(AuthContext);

  const [data, setData] = useState({});
  const [keyword, setKeyword] = useState("");
  const getData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.lifeTimePL + "?username=" + keyword
    );
    if (status === 200) {
      if (response_users.success) {
        setData(response_users?.results);
      }
    }
  };
  const [isLoading, setisLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({});
  const handleFormSubmit = async (formData) => {
    setisLoading(true);
    const { data: response_data, status } = await apiPost(
      apiPath.bankingCreate,
      {
        pageType: "balance",
        transactionInsert: [
          {
            user_id: data?.userId,
            dw_type: "D",
            amount: Number(formData?.amount),
          },
        ],
      }
    );
    if (status === 200) {
      if (response_data.success) {
        toast.success(response_data.message);
        reset();
        getData();
        setisLoading(false);
      } else {
        setisLoading(false);
        toast.error(response_data.message);
      }
    } else {
      setisLoading(false);
      toast.error(response_data.message);
    }
  };

  const onSubmit2 = async (request) => {
    try {
      const { status, data: response_users } = await apiPut(
        apiPath.profileChangeStatus + "/" + data?.userId,
        { status: request, pageType: "balance" }
      );
      if (status === 200) {
        if (response_users.success) {
          toast.success(response_users.message);
          getData();
        } else {
          toast.error(response_users.message);
        }
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };
  return (
    <div>
      <section className="py-4 main-inner-outer">
        <Container fluid>
          <div className="inner-wrapper">
            <h2 className="common-heading">Balance Summary</h2>

            <div className="find-member-sec">
              <Form className="mb-4">
                <Form.Group className="position-relative ">
                  <Form.Control
                    type="text"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    placeholder="Enter User Id..."
                  />
                  <i className="fas fa-search"></i>
                </Form.Group>
                <div className="d-flex flex-wrap block-search-sec align-items-center">
                  <Button
                    className="mb-2 mx-1 theme_dark_btn h-36"
                    onClick={getData}
                  >
                    Search
                  </Button>
                  <Link to={`/transaction-history-2/${data?.userId}/${data?.userType}`}>
                    {" "}
                    <Button className="mb-2 mx-1 theme_light_btn h-36">
                      Statement
                    </Button>
                  </Link>

                  <Form.Group className="">
                    <Form.Select
                      aria-label="Default select example"
                      className="search-user-change-status ms-0 h-36"
                      onChange={(e) => {
                        onSubmit2(e.target.value);
                      }}
                    >
                      <option value="">Change Status</option>
                      <option value="active">Active</option>
                      <option value="suspend">Suspend</option>
                      <option value="locked">Locked</option>
                      <option value="cheater">Cheater</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </Form>
            </div>

            <section className="account-table">
              <div className="profile-tab">
                <Row>
                  <Col lg={7} md={12}>
                    <h2 className="common-heading">Profile</h2>

                    <Table>
                      <thead>
                        <tr>
                          <th scope="col" colSpan="4" className="text-start">
                            About Balance
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.userType === "user" && (
                          <tr>
                            <td className="text-start" width="25%">
                              Profile & Loss
                            </td>
                            <td className="text-start" colSpan="3">
                              {data.finalPL ? (
                                <span>
                                  {helpers.currencyFormat(
                                  data?.finalPL
                                  )}
                                </span>
                              ) : (
                                <span>--</span>
                              )}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td className="text-start" width="25%">
                            Deposit
                          </td>
                          <td className="text-start" colSpan="3">
                            {data?.totalDeposit
                              ? helpers.currencyFormat(data?.totalDeposit)
                              : "--"}
                          </td>
                        </tr>

                        <tr>
                          <td className="text-start" width="25%">
                            Withdraw
                          </td>
                          <td className="text-start text-danger" colSpan="3">
                            {data?.withdraw >= 0
                              ? helpers.currencyFormat(data?.withdraw)
                              : "--"}
                          </td>
                        </tr>

                        <tr>
                          <td className="text-start" width="25%">
                            Balance
                          </td>
                          <td className="text-start" colSpan="3">
                            {data?.balance
                              ? helpers.currencyFormat(data?.balance)
                              : "--"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                    {data?.userType === "user" && (
                      <Table>
                        <thead>
                          <tr>
                            <th scope="col" colSpan="4" className="text-start">
                              Liab Details
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-start" width="25%">
                              Liability
                            </td>
                            <td className="text-start text-danger" colSpan="3">
                              {data?.exposerAmount
                                ? helpers.currencyFormat(
                                    Math.abs(data?.exposerAmount)
                                  )
                                : "--"}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-start" width="25%">
                              Final Balance
                            </td>

                            <td className="text-start" colSpan="3">
                              {Number(data?.balance) -
                              Math.abs(data?.exposerAmount)
                                ? helpers.currencyFormat(
                                    Number(data?.balance) -
                                      Math.abs(data?.exposerAmount)
                                  )
                                : "--"}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                    <Table>
                      <thead>
                        <tr>
                          <th scope="col" colSpan="4" className="text-start">
                            Profile Balance
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-start" width="25%">
                            Current Balance
                          </td>
                          <td className="text-start" colSpan="3">
                            {data?.balance
                              ? helpers.currencyFormat(data?.balance)
                              : "--"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    {!isEmpty(data) && (
                      <>
                       {user?.userType === "owner" || user?.userType === "sub_owner"|| user?.userType === "super_admin"?
                      <Table>
                        <thead>
                          <tr>
                            <th scope="col" colSpan="4" className="text-start">
                              To Be Deposited
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="text-start" width="50%" colSpan="1">
                             
                                <Form onSubmit={handleSubmit(handleFormSubmit)} className="player-b-form">
                                  {" "}
                                  <button
                                    type="submit"
                                    disabled={
                                      watch("amount") > 0 ? false : true
                                    }
                                    className="btn theme_dark_btn "
                                  >
                                    Submit
                                  </button>
                                  <Form.Control
                                    type="number"
                                    placeholder="Amount"
                                    className={
                                      errors.amount ? " is-invalid" : ""
                                    }
                                    {...register("amount", {
                                      required: "Please enter amount",
                                      min: {
                                        value: 1,
                                        message:
                                          " Amount should be greater then 0",
                                      },
                                    })}
                                    style={{maxWidth:`400px`}}
                                  />
                                  {errors.amount && errors.amount.message && (
                                    <label className="invalid-feedback text-left">
                                      {errors.amount.message}
                                    </label>
                                  )}
                                  {/* <Form.Control
                                  type="password"
                                  placeholder="Your Password"
                                  className={
                                    errors.password
                                      ? " is-invalid mb-1"
                                      : "mb-1"
                                  }
                                  {...register("password", {
                                    required: "Please enter password",
                                  })}
                                />{" "}
                                {errors.password && errors.password.message && (
                                  <label className="invalid-feedback text-left">
                                    {errors.password.message}
                                  </label>
                                )} */}
                                </Form>
                            
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      :""}</>
                    )}
                  </Col>
                </Row>
              </div>
            </section>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default PlayerBalance;
