import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import constants from "../../utils/constants";
import { useEffect } from "react";
import obj from "../../utils/constants";
import { isEmpty } from "lodash";
const ViewDialog = () => {
  const params = useParams();
  const [isLoader, setLoader] = useState(false);
  const [matchData, setMatchData] = useState([]);
  const [detailsData, setDetailsData] = useState({});
  const [search_params, setSearchParams] = useState({
    userId: params.userId,
    userType: params.userType,
    eventId: params.id,
    marketId: params.marketId,
    betType: params.type == "Book Maker" ? "bookmaker" : "betFair",
  });

  const getMatchData = async () => {
    setLoader(true);

    const { status, data: response_users } = await apiGet(
      apiPath.downlineDataRisk,
      search_params
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setLoader(false);
          setMatchData(response_users.results);
        }
      }
    }
  };
  useEffect(() => {
    setSearchParams({
      userId: params.userId,
      userType: params.userType,
      eventId: params.id,
      marketId: params.marketId,
      betType: params.type == "Book Maker" ? "bookmaker" : "betFair",
    });
  }, [params]);

  useEffect(() => {
    getMatchData();
    getDetails();
  }, [search_params?.userId,search_params?.eventId,search_params?.betType, search_params?.marketId]);
  

  const getDetails = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.riskManagementDetails + `?eventId=${search_params.eventId}&marketId=${search_params.marketId}`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setDetailsData(response_users.results);
        }
      }
    }
  };

  var first = 0;
  var third = 0;
  var second = 0;
  return (
    <section className="main-inner-outer py-4 pop-warp">
      <section className="account-table">
        <div className="container-fluid">
          <div className="db-sec d-flex justify-content-between align-items-center mb-2 odds-game-team">
            <h2 className="">
              {detailsData?.eventName} <span>{params?.type}</span>  
            </h2>
            <Button className="green-btn" onClick={() => window.close()}>
             X Close
            </Button>
          </div>
          {matchData?.length > 0 && (
          <table class="table01">
            <thead>
              <tr>
                <th class="align-L" width="" rowspan="2" style={{textAlign:`left`}}>
                  Downline
                </th>
                <th class="align-C border-l bgcolor-sub" width="" colspan="3">
                  Player P/L
                </th>
              </tr>
              <tr>
                <th class="border-l bgcolor-sub" width="7%">
                 {detailsData?.jsonData
                        ? detailsData?.jsonData[0]?.RunnerName
                        : ""}
                </th>
                {detailsData?.jsonData?.length > 2 && (
                      <th  class="border-l bgcolor-sub" width="7%">
                        The Draw
                      </th>
                    )}
                <th class="border-l bgcolor-sub" width="7%">
                {detailsData?.jsonData
                        ? detailsData?.jsonData[1]?.RunnerName
                        : ""}
                </th>
              </tr>
            </thead>
            {!isLoader ? (
              <>
            <tbody id="content">
            {matchData &&
                      matchData?.length > 0 &&
                      matchData?.map((res) => {
                       
                        // console.log('res?.position[2]?.position',res?.position[0]?.position, res?.position[1]?.position, res?.position[2]?.position)
                        
                        // console.log('-----------------',res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[0]?.SelectionId)?.position)

                        // console.log('-----------------',res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[1]?.SelectionId)?.position)
                        
                        if(Object.keys(detailsData).length !== 0){
                          // console.log("eeeeeeeeeeeeeeee",detailsData);
                          first +=
                          res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[0]?.SelectionId)?.position != 0
                              ? res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[0]?.SelectionId)?.position
                              : 0;
                          third +=
                          res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[2]?.SelectionId)?.position != 0
                              ? res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[2]?.SelectionId)?.position
                              : 0;
                          second +=
                          res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[1]?.SelectionId)?.position != 0
                              ? res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[1]?.SelectionId)?.position
                              : 0;
                        }
                        

                        return (first == 0 &&
                          second == 0 &&
                          third == 0)?(""):(

                          <tr id="tempTr">
                            <td class="td-UID">
                              <span class="order" id="number">
                                1..
                              </span>
                              {search_params?.userType == "agent" ? (
                                    <>
                                      <a href={"#"} className="text-primary">
                                        <span>
                                          {
                                            constants?.user_status[
                                            res?.userType || ""
                                            ]
                                          }
                                        </span>
                                      </a>
                                      {res?.username || null}
                                    </>
                                  ) : (
                                    <Link
                                      to={`/DownlinePnl/${res._id}/${res?.userType}/${search_params.eventId}/${params?.marketId}/${params?.type}`}
                                    >
                                      <a href={"#"} className="text-primary">
                                      <span>
                                        {constants?.user_status[res?.userType || ""]}
                                      </span>
                                    </a>
                                      {res?.username || null}
                                    </Link>
                                  )}
                            </td>
                            <td className="text-right-data">
                            {Math.sign(res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[0]?.SelectionId)?.position?.toFixed(2)) ===
                                    -1 ? (
                                      <span className="text-success">
                                    {Math.abs(res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[0]?.SelectionId)?.position?.toFixed(2))}
                                  </span>
                                  ) : (
                                   <span className="text-danger">
                                    {-res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[0]?.SelectionId)?.position?.toFixed(2)}
                                    </span>
                                  )}
                            </td>
                            {detailsData?.jsonData?.length > 2 && (
                            <td className="text-right-data">
                               {Math.sign(res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[2]?.SelectionId)?.position?.toFixed(2)) ===
                                     -1? (
                                        <span className="text-success">
                                      {Math.abs(res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[2]?.SelectionId)?.position?.toFixed(2))}
                                    </span>
                                    ) : (
                                     
                                      <span className="text-danger">
                                      {-res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[2]?.SelectionId)?.position?.toFixed(2)}                                      
                                      </span>
                                    )}

                                 
                            </td>)}
                            <td className="text-right-data">
                            {Math.sign(res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[1]?.SelectionId)?.position?.toFixed(2)) ===
                                    -1 ? (
                                     <span className="text-success">
                                    {Math.abs(res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[1]?.SelectionId)?.position?.toFixed(2))}
                                  </span>
                                  ) : (
                                    <span className="text-danger">
                                    {-res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[1]?.SelectionId)?.position?.toFixed(2)}
                                    </span>
                                  )}
                            </td>
                          </tr>
                          );
                          })}
                        </tbody>
                        {matchData?.length > 0 && (
                        <tfoot>
                          <tr class="total" id="tempTotalTr">
                            <td class="align-L">Total</td>
                            <td id="total_selection_exposure_1" className="text-right-data"> 
                              {Math.sign(first?.toFixed(2)) ===
                            -1 ? (
                              <span className="text-success">
                            {Math.abs(first?.toFixed(2))}
                          </span>
                          ) : (
                            <span className="text-danger">{-first?.toFixed(2)}</span>
                          )}</td>
                            
                            {detailsData?.jsonData?.length > 2 && 
                            <td id="total_selection_exposure_2" className="text-right-data">
                            {Math.sign(third?.toFixed(2)) ===
                            -1 ? (
                             <span className="text-success"> {Math.abs(third?.toFixed(2))}</span>
                          ) : (<span className="text-danger">{-third?.toFixed(2)}</span>
                            
                          )}
                            </td>}
                            <td className="text-right-data">{Math.sign(second?.toFixed(2)) ===
                          -1 ? (  <span className="text-success">
                          {Math.abs(second?.toFixed(2))}
                        </span>
                        ) : ( <span className="text-danger">{-second?.toFixed(2)}</span>
                          
                        )}</td>
                          </tr>
                        </tfoot>)}
                        </>
                        ) : (
                          <div
                            id="loader"
                            className="spinner"
                            style={{ display: `block` }}
                          ></div>
                        )}
                      </table>
                      )}
          {/* {matchData?.length > 0 && (
            <div className="responsive">
              <Table>
                <thead>
                  <tr>
                    <th scope="col">
                      Downline
                    </th>
                    <th scope="col">
                      {" "}
                      {detailsData?.jsonData
                        ? detailsData?.jsonData[0]?.RunnerName
                        : ""}
                    </th>
                    {detailsData?.jsonData?.length > 2 && (
                      <th scope="col">
                        The Draw
                      </th>
                    )}
                    <th scope="col">
                      {detailsData?.jsonData
                        ? detailsData?.jsonData[1]?.RunnerName
                        : ""}
                    </th>
                  </tr>
                </thead>
                {!isLoader ? (
                  <tbody>
                    {matchData &&
                      matchData?.length > 0 &&
                      matchData?.map((res) => {
                       
                        // console.log('res?.position[2]?.position',res?.position[0]?.position, res?.position[1]?.position, res?.position[2]?.position)
                        
                        // console.log('-----------------',res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[0]?.SelectionId)?.position)

                        // console.log('-----------------',res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[1]?.SelectionId)?.position)
                        
                        if(Object.keys(detailsData).length !== 0){
                          // console.log("eeeeeeeeeeeeeeee",detailsData);
                          first +=
                          res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[0]?.SelectionId)?.position != 0
                              ? res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[0]?.SelectionId)?.position
                              : 0;
                          third +=
                          res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[2]?.SelectionId)?.position != 0
                              ? res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[2]?.SelectionId)?.position
                              : 0;
                          second +=
                          res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[1]?.SelectionId)?.position != 0
                              ? res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[1]?.SelectionId)?.position
                              : 0;
                        }
                        

                        return (first == 0 &&
                          second == 0 &&
                          third == 0)?(""):(
                            <>
                              <tr>
                                <td
                                  className="text-start"
                                // style={{ display: "flex", width: "100%" }}
                                >
                                  {search_params?.userType == "agent" ? (
                                    <>
                                      <a href={"#"} className="text-primary">
                                        <span>
                                          {
                                            constants?.user_status[
                                            res?.userType || ""
                                            ]
                                          }
                                        </span>
                                      </a>
                                      {res?.username || null}
                                    </>
                                  ) : (
                                    <Link
                                      to={`/DownlinePnl/${res._id}/${res?.userType}/${search_params.eventId}/${params?.marketId}/${params?.type}`}
                                    >
                                      <a href={"#"} className="text-primary">
                                      <span>
                                        {constants?.user_status[res?.userType || ""]}
                                      </span>
                                    </a>
                                      {res?.username || null}
                                    </Link>
                                  )}
                                </td>
                                <td>
                                 
                                  {Math.sign(res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[0]?.SelectionId)?.position?.toFixed(2)) ===
                                    -1 ? (
                                      <span className="text-success">
                                    {Math.abs(res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[0]?.SelectionId)?.position?.toFixed(2))}
                                  </span>
                                  ) : (
                                   <span className="text-danger">
                                    {-res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[0]?.SelectionId)?.position?.toFixed(2)}
                                    </span>
                                  )}

                                </td>
                                {detailsData?.jsonData?.length > 2 && (
                                  <td>
                                    {Math.sign(res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[2]?.SelectionId)?.position?.toFixed(2)) ===
                                     -1? (
                                        <span className="text-success">
                                      {Math.abs(res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[2]?.SelectionId)?.position?.toFixed(2))}
                                    </span>
                                    ) : (
                                     
                                      <span className="text-danger">
                                      {-res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[2]?.SelectionId)?.position?.toFixed(2)}                                      
                                      </span>
                                    )}

                                  </td>
                                )}
                                <td>
                                  {Math.sign(res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[1]?.SelectionId)?.position?.toFixed(2)) ===
                                    -1 ? (
                                     <span className="text-success">
                                    {Math.abs(res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[1]?.SelectionId)?.position?.toFixed(2))}
                                  </span>
                                  ) : (
                                    <span className="text-danger">
                                    {-res?.position?.find((res) => res?.selectionId == detailsData?.jsonData[1]?.SelectionId)?.position?.toFixed(2)}
                                    </span>
                                  )}

                                </td>
                              </tr>
                            </>
                          
                        );
                      })}
                    {matchData?.length > 0 && (
                      <tr style={{ fontWeight: "600" }}>
                        <td
                          className="text-start"
                        // style={{ display: "flex", width: "100%" }}
                        >
                          Total
                        </td>
                        <td>
                          {Math.sign(first?.toFixed(2)) ===
                            -1 ? (
                              <span className="text-success">
                            {Math.abs(first?.toFixed(2))}
                          </span>
                          ) : (
                            <span className="text-danger">{-first?.toFixed(2)}</span>
                          )}

                        </td>
                        {detailsData?.jsonData?.length > 2 && <td>
                          {Math.sign(third?.toFixed(2)) ===
                            -1 ? (
                             <span className="text-success"> {Math.abs(third?.toFixed(2))}</span>
                          ) : (<span className="text-danger">{-third?.toFixed(2)}</span>
                            
                          )}
                        </td>}

                        <td>{Math.sign(second?.toFixed(2)) ===
                          -1 ? (  <span className="text-success">
                          {Math.abs(second?.toFixed(2))}
                        </span>
                        ) : ( <span className="text-danger">{-second?.toFixed(2)}</span>
                          
                        )}</td>
                      </tr>
                    )}

                    {isEmpty(matchData) ? (
                      <tr>
                        <td colSpan={9}>No records found</td>
                      </tr>
                    ) : null}
                  </tbody>
                ) : (
                  <div
                    id="loader"
                    className="spinner"
                    style={{ display: `block` }}
                  ></div>
                )}
              </Table>
            </div>
          )} */}
        </div>
      </section>
    </section>
  );
};

export default ViewDialog;
