import React, { useState, useContext } from "react";
import { Button, Table } from "react-bootstrap";
import { FilterBar } from "../../components/FilterBar";
import helpers from "../../utils/helpers";
import AuthContext from "../../context/AuthContext";
import ReactPaginate from "react-paginate";
import { startCase } from "lodash";
export const BettingHistoryTab = ({
  filter,
  setFilter,
  data,
  title,
  getData,
  betType,
  url,
  viewpage,
  handlePageClick,
  pageCount,
  redirectCasino,
}) => {
  let { user } = useContext(AuthContext);
  console.log("betType", betType);
  const [getProperty, setProperty] = useState("none");
  const showDetail = (event, id) => {
    const detailDiv = document.getElementById(id);

    if (detailDiv.style.display === "none") {
      detailDiv.style.setProperty("display", "contents");
      event.target.className = "fas fa-minus-square pe-2";
    } else {
      detailDiv.style.setProperty("display", "none");
      event.target.className = "fas fa-plus-square pe-2";
    }
  };

  return (
    <div className="common-container">
      {/* <FilterBar filter={filter} setFilter={setFilter} getData={getData} /> */}
      <div className="batting-content">
        <p>
          Betting History enables you to review the bets you have placed.
          Specify the time period during which your bets were placed, the type
          of markets on which the bets were placed, and the sport.
        </p>
        <p>Betting History is available online for the past 30 days.</p>
      </div>
      <div className="account-table batting-table profit_loss_table">
        <div className="responsive">
          {betType === "casino" ? (
            <Table className="minwidth-1480">
              <thead>
                <tr>
                  <th scope="col">Bet ID</th>
                  <th scope="col"> PL ID</th>
                  <th scope="col">Market</th>
                  {/* <th scope="col">Selection</th> */}
                  {/* <th scope="col">Type</th> */}
                  <th scope="col">Bet Placed</th>
                  {/* <th scope="col">Odds Req.</th> */}
                  <th scope="col">Stake</th>
                  {/* <th scope="col">Avg. odds Matched</th> */}
                  <th scope="col">Profit / Loss</th>
                </tr>
              </thead>
              {data?.data && data?.data?.length > 0 ? (
                data?.data?.map((item, index) => {
                  // const resultTotalStake = item?.bets_list.reduce((a, v) => {
                  //   a = parseInt(a) + parseInt(v.amount);
                  //   return a;
                  // }, 0);

                  return (
                    <>
                      <tr
                        id="summary0"
                        style={{ display: "table-row" }}
                        key={index + 1}
                      >
                        <td>
                          {" "}
                          <i
                            id={"icon_" + item?.platformTxId}
                            className="fas fa-plus-square"
                            onClick={(e) => showDetail(e, item?.platformTxId)}
                          ></i>{" "}
                          {item?.platformTxId}
                        </td>
                        <td>{item?.clientName}</td>
                        <td id="title" className="align-L">
                          Casino
                          <span className="angle_unicode">&#10148;</span>
                          <strong> {item?.casinoName}</strong>
                          <span className="angle_unicode">&#10148;</span>
                          {item?.gameCode}
                        </td>
                        {/* <td>null</td> */}
                        {/* <td>back</td> */}
                        <td id="settledDate">
                          {" "}
                          {helpers.dateFormat(item.timeInserted, user.timeZone)}
                        </td>
                        {/* <td>0.00</td> */}
                        <td>{item?.betAmount}</td>
                        {/* <td>0.00</td> */}
                        <td>
                          {Math.sign(item?.playerPL) === -1 ? (
                            <span className="text-danger">
                              ({helpers.currencyFormat(item?.playerPL)})
                            </span>
                          ) : (
                            <span className="text-success">
                              (
                              {helpers.currencyFormat(Math.abs(item?.playerPL))}
                              )
                            </span>
                          )}
                        </td>
                      </tr>

                      <tr
                        className="expand"
                        id={item?.platformTxId}
                        style={{ display: getProperty }}
                      >
                        <td colSpan="7" className="expand_wrap">
                          <table className="table-commission">
                            <tbody>
                              <tr>
                                <th>Bet Taken</th>
                                <th>Odds Req.</th>
                                <th>Stake</th>
                                <th>Liability</th>
                                <th>Odds Matched</th>
                                <th>Action</th>
                              </tr>
                              <tr
                                id="txRow0"
                                style={{ display: "table-row" }}
                                className="even"
                              >
                                <td id="betID">
                                  {" "}
                                  {helpers.dateFormat(
                                    item.timeInserted,
                                    user.timeZone
                                  )}
                                </td>
                                <td>0.00</td>
                                <td>{item?.betAmount}</td>
                                <td>
                                  {item?.loseAmount ? item?.loseAmount : "-"}
                                </td>
                                <td>0.00</td>
                                <td>
                                  <Button
                                    className="theme_dark_btn"
                                    onClick={() =>
                                      redirectCasino(
                                        item?.clientName,
                                        item?.platform,
                                        item?.platformTxId,
                                        2
                                      )
                                    }
                                  >
                                    Get Result
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10}>
                    <span>You have no bets in this time period.</span>
                  </td>
                </tr>
              )}
            </Table>
          ) : title == "parlay" ? (
            <Table className="minwidth-1480">
              <thead>
                <tr>
                  <th scope="col" className="text-start">
                    Market
                  </th>
                  <th scope="col" className="text-end">
                    {" "}
                    Settled Date
                  </th>
                  <th scope="col" className="text-end">
                    {" "}
                    Bet ID
                  </th>
                  <th scope="col" className="text-end">
                    Stake
                  </th>
                  <th scope="col" className="text-end">
                    Profit / Loss
                  </th>
                </tr>
              </thead>
              {data?.length > 0 ? (
                data?.map((item, index) => {
                  return (
                    <>
                      <tr
                        id="summary0"
                        style={{ display: "table-row" }}
                        key={index + 1}
                      >
                        <td className="text-start">
                          {item?.isDeclared ? (
                            <>
                              {item?.result == "loss" ? (
                                <strong
                                  // className="d-block"
                                  style={{ color: "red" }}
                                >
                                  {startCase(item?.result)}
                                </strong>
                              ) : (
                                <strong
                                  // className="d-block"
                                  style={{ color: "green" }}
                                >
                                  {startCase(item?.result)}
                                </strong>
                              )}
                            </>
                          ) : (
                            "..."
                          )}
                          <span className="angle_unicode">▸</span>
                          <strong>
                            {item?.bets?.length == 0
                              ? ""
                              : item?.bets?.length == 1
                              ? "SINGLE"
                              : item?.bets?.length == 2
                              ? "DOUBLE"
                              : item?.bets?.length == 3
                              ? "TREBLE"
                              : "ACCMULATOR"}
                          </strong>
                        </td>
                        <td id="settledDate" className="text-end">
                          {" "}
                          {helpers.dateFormat(item.timeInserted)}
                        </td>

                        <td id="settledDate" className="text-end">
                          {" "}
                          {item?.matchBetId}
                        </td>
                        <td id="settledDate" className="text-end">
                          {" "}
                          {item?.amount}
                        </td>
                        <td className="text-end">
                          <a id="pl0" className="expand-open" href="#">
                            {item?.isDeclared ? (
                              <>
                                {item?.result == "loss" ? (
                                  <strong style={{ color: "red" }}>
                                    {Number(item?.loseAmount)?.toFixed(2)}
                                  </strong>
                                ) : (
                                  <strong style={{ color: "green" }}>
                                    {Number(item?.profitAmount)?.toFixed(2)}
                                  </strong>
                                )}
                              </>
                            ) : (
                              "..."
                            )}
                          </a>
                          <i
                            id={"icon_" + item?._id}
                            className="fas fa-plus-square"
                            onClick={(e) => showDetail(e, item?._id)}
                          ></i>
                        </td>
                      </tr>
                      {item?.bets?.length > 0 && (
                        <tr
                          className="expand"
                          id={item?._id}
                          style={{ display: getProperty }}
                          key={item?._id}
                        >
                          <td colSpan="5" className="expand_wrap">
                            <table className="table-commission">
                              <tbody>
                                <tr>
                                  {/* <th>User Name</th> */}
                                  <th className="text-start">Fancy Name</th>
                                  <th className="text-end">Selection</th>
                                  <th className="text-end">Odds</th>
                                  {/* <th>Stake</th> */}
                                  {/* <th>Type</th> */}
                                  <th className="text-end">Profit/Loss</th>
                                </tr>
                                {item?.bets?.map((betData, i) => {
                                  return (
                                    <tr
                                      id="txRow0"
                                      style={{
                                        display: "table-row",
                                      }}
                                      className="even"
                                      key={i + 1}
                                    >
                                      {/* <td id="betID">
                                      {betData?.clientName}
                                    </td> */}
                                      <td id="betID">{betData?.fancyName}</td>
                                      <td
                                        id="matchSelection"
                                        className="text-end"
                                      >
                                        {betData?.runnerName}
                                      </td>
                                      <td
                                        id="txOddsMatched"
                                        className="text-end"
                                      >
                                        {betData?.bhav}
                                      </td>
                                      {/* <td id="txStake">
                                      {" "}
                                      {betData?.amount}
                                    </td>
                                    <td>
                                      <span
                                        id="matchType"
                                        className={
                                          betData?.betType ===
                                          "lay"
                                            ? "lay"
                                            : "back"
                                        }
                                      >
                                        {betData?.betType}
                                      </span>
                                    </td> */}
                                      <td id="placed" className="text-end">
                                        {" "}
                                        {/* {Number(
                                        betData?.result == "win"
                                          ? betData?.profitAmount
                                          : betData?.loseAmount
                                      )?.toFixed(2)} */}
                                        {betData?.isDeclared ? (
                                          <>
                                            {betData?.result == "loss" ? (
                                              <strong
                                                style={{
                                                  color: "red",
                                                }}
                                              >
                                                {startCase(betData?.result)}
                                              </strong>
                                            ) : (
                                              <strong
                                                style={{
                                                  color: "green",
                                                }}
                                              >
                                                {startCase(betData?.result)}
                                              </strong>
                                            )}
                                          </>
                                        ) : (
                                          "..."
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10}>
                    <span>You have no bets in this time period.</span>
                  </td>
                </tr>
              )}
            </Table>
          ) : (
            <Table className="minwidth-1480">
              <thead>
                <tr>
                  <th scope="col">PL ID</th>
                  <th scope="col"> Bet ID</th>
                  <th scope="col">Bet placed</th>
                  <th scope="col">IP Address </th>
                  <th scope="col">Market</th>
                  <th scope="col">Selection</th>
                  <th scope="col">Type</th>
                  <th scope="col">Odds req.</th>
                  <th scope="col">Stake </th>
                  <th scope="col">Profit/Loss </th>
                </tr>
              </thead>
              {data?.data && data?.data?.length > 0 ? (
                data?.data?.map((item) => {
                  return (
                    <tr>
                      <td>{item?.clientName || "-"}</td>
                      <td>
                        {" "}
                        {betType == "fancy"
                          ? item?.sessionBetId
                          : item?.matchBetId || "N/A"}
                      </td>
                      <td>
                        {" "}
                        {helpers.dateFormat(item?.timeInserted, user.timeZone)}
                      </td>
                      <td>
                        {" "}
                        {(item?.ipAddress &&
                          item?.ipAddress.replace("::ffff:", "")) ||
                          "N/A"}
                      </td>
                      <td className="text-start">
                        {`${
                          item?.eventType === "4"
                            ? "Cricket"
                            : item?.eventType === "1"
                            ? "Soccer"
                            : item?.eventType === "2"
                            ? "Tennis"
                            : "Casino"
                        }`}
                        <span
                          className="angle_unicode"
                          style={{ padding: "0 5px" }}
                        >
                          &#10148;
                        </span>
                        <strong style={{ padding: "0 5px" }}>
                          {betType === "casino"
                            ? item?.casinoName
                            : item?.matchName}
                        </strong>
                        <span
                          className="angle_unicode"
                          style={{ padding: "0 5px" }}
                        >
                          &#10148;
                        </span>
                        {betType === "sportBook" && item?.fancyName}
                        {betType === "fancy" && item?.fancyName}{" "}
                        {betType === "casino" && item?.casinoType}{" "}
                        {betType === "toss" && "Toss"}{" "}
                        {betType === "tie" && "Tie"}{" "}
                        {betType === "betfair" && "Match Odds"}
                        {betType === "bookmaker" && "Bookmaker"}
                      </td>
                      <td>
                        {betType === "fancy"
                          ? item?.betRun + "/" + item?.bhav
                          : betType === "sportBook"
                          ? item?.runnerName
                          : item?.teamName}
                      </td>
                      <td>
                        {" "}
                        {betType == "fancy"
                          ? item?.type
                          : item?.betType || "N/A"}{" "}
                      </td>
                      <td> {item?.bhav || "N/A"} </td>
                      <td> {item?.amount || "N/A"} </td>
                      {item?.status !== "voided" ? (
                        betType == "betfair" ||
                        betType == "Bookmaker" ||
                        betType === "toss" ||
                        betType === "tie" ? (
                          <td>
                            {(item?.teamSelectionWin == item?.selectionId &&
                              item?.betType == "back") ||
                            (item?.teamSelectionWin != item?.selectionId &&
                              item?.betType == "lay") ? (
                              <span className="text-success">
                                {item?.profitAmount}
                              </span>
                            ) : (
                              <span className="text-danger">
                                -({item?.loseAmount})
                              </span>
                            )}
                          </td>
                        ) : betType === "sportBook" ? (
                          <td>
                            {item?.teamSelectionWin &&
                            item?.teamSelectionWin
                              .split(",")
                              .includes(item?.fancySelectionId) ? (
                              <span className="text-success">
                                {item?.profitAmount}
                              </span>
                            ) : (
                              <span className="text-danger">
                                -({item?.loseAmount})
                              </span>
                            )}
                          </td>
                        ) : (
                          <td>
                            {item?.type == "No" ? (
                              item?.decisionRun < item?.betRun ? (
                                <span className="text-success">
                                  {item?.profitAmount}
                                </span>
                              ) : (
                                <span className="text-danger">
                                  -({item?.loseAmount})
                                </span>
                              )
                            ) : item?.decisionRun >= item?.betRun ? (
                              <span className="text-success">
                                {item?.profitAmount}
                              </span>
                            ) : (
                              <span className="text-danger">
                                -({item?.loseAmount})
                              </span>
                            )}
                          </td>
                        )
                      ) : (
                        <td>0.00</td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10}>
                    <span>You have no bets in this time period.</span>
                  </td>
                </tr>
              )}
            </Table>
          )}

          <div className="bottom-pagination">
            <ReactPaginate
              breakLabel="..."
              nextLabel=" >"
              forcePage={viewpage}
              onPageChange={handlePageClick}
              pageRangeDisplayed={10}
              pageCount={pageCount}
              previousLabel="< "
              renderOnZeroPageCount={null}
              activeClassName="p-1"
              activeLinkClassName="pagintion-li"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
