import React, { useState, useContext } from "react";
import { Container, Row, Col, Form, Table, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import BetTypeListing from "./BetTypeListing";
import FancyBetTypeListing from "./FancyBetTypeListing";
import FancyBetTypeListingNew from "./FancyBetTypeListingNew";
import SportBookListing from "./SportBookListing";
import PremiumListing from "./PremiumListing";
import BinaryListing from "./BinaryListing";
import { apiGet, apiPost } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import { useEffect } from "react";
import obj from "../../utils/constants";
import helpers from "../../utils/helpers";
import AuthContext from "../../context/AuthContext";
import io from "socket.io-client";
import { isEmpty } from "lodash";
const RiskManagement = () => {
  const navigate = useNavigate();
  const [headerTab, setHeaderTab] = useState("amount");
  const [matchData, setMatchData] = useState([]);
  const [topData, setTopdata] = useState({});
  const [eventId, setEventId] = useState("");
  const [matchName, setMatchName] = useState("");
  const [detailsData, setDetailsData] = useState({});
  const [fancyCentralizedIdArray, setFancyCentralizedIdArray] = useState([]);
  const [fancyList, setFancyList] = useState([]);
  const [sportBookList, setSportBookList] = useState([]);
  const [premiumCricketList, setPremiumCricketList] = useState([]);
  const [betFairData, setBetFairData] = useState([]);
  const [bookmakerData, setBookmakerData] = useState([]);
  const [betFairCentralizedIds, setBetFairCentralizedIds] = useState([]);
  const [bookmakerCentralizedIds, setBookMakerCentralizedIds] = useState([]);
  const [betFaireDataCheck, setBetFaireDataCheck] = useState(false);
  const [bookmakerDataCheck, setBookmakerDataCheck] = useState(false);
  const [sportType, setSportType] = useState("4");
  const [fancyOdds, setFancyOddds] = useState({});
  const [search_params, setSearchParams] = useState({
    eventType: 4,
    status: "in_play",
  });
  let { user } = useContext(AuthContext);
  const getMatchData = async (id) => {
    let obj = {
      ...search_params,
      sportType: sportType == "all" ? "" : sportType,
      status: "in_play",
    };

    const { status, data: response_users } = await apiGet(
      apiPath.matchFilterList,
      obj
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setMatchData(response_users.results);
          setDetailsData({});
          setEventId("");
          setMatchName("");
        }
      }
    }
  };
  const getTopAmount = async () => {
    const { status, data: response_users } = await apiGet(apiPath.riskProfile, {
      sportType: sportType == "all" ? "" : sportType,
    });
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setTopdata(response_users.results);
        }
      }
    }
  };

  const getRiskData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.riskMatchList,
      {
        userId: user._id,
        userType: user.userType,
        sportType: sportType == "all" ? "" : sportType,
      }
    );
    if (status === 200) {
      if (response_users.success) {
        setBetFaireDataCheck(true);
        if (response_users.results?.length > 0) {
          const fancyCentralizedIdArr = response_users?.results.fancyList?.map(
            (rt) => rt.marketId
          );
          setFancyCentralizedIdArray(fancyCentralizedIdArr);
          setBetFairData(
            response_users?.results?.length > 0
              ? response_users?.results?.sort(function (a, b) {
                  return new Date(b.eventDateTime) - new Date(a.eventDateTime);
                })
              : []
          );
          if (response_users?.results?.length > 0) {
            getOdds(
              response_users?.results?.map((res) => {
                return res?.marketId;
              })
            );
          }

          const betFairCentralizedIdsData =
            response_users?.results &&
            response_users?.results.length > 0 &&
            response_users?.results?.map((r) => r.marketId);

          if (betFairCentralizedIdsData && betFairCentralizedIdsData.length) {
            setBetFairCentralizedIds(betFairCentralizedIdsData);
          }
        } else {
          setBetFairCentralizedIds([]);
          setBetFairData([]);
          setFancyCentralizedIdArray([]);
        }
      }
    }
  };

  const getRiskDataBookmaker = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.riskMatchListBookmaker,
      { sportType: sportType == "all" ? "" : sportType }
    );
    if (status === 200) {
      if (response_users.success) {
        setBookmakerDataCheck(true);
        if (response_users?.results && response_users.results.length > 0) {
          setBookmakerData(
            response_users?.results?.length > 0
              ? response_users?.results?.sort(function (a, b) {
                  return new Date(b.eventDateTime) - new Date(a.eventDateTime);
                })
              : []
          );
          const bookMakerCentralizedIdsData = response_users?.results?.map(
            (r) => r.eventId
          );
          if (bookMakerCentralizedIdsData?.length > 0) {
            getOddsBookmaker(bookMakerCentralizedIdsData);
          }
          if (
            bookMakerCentralizedIdsData &&
            bookMakerCentralizedIdsData.length
          ) {
            setBookMakerCentralizedIds(bookMakerCentralizedIdsData);
          }
        } else {
          setBookMakerCentralizedIds([]);
          setBookmakerData([]);
        }
      }
    }
  };

  const getRiskFancyData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.riskManagementFancy,
      { sportType: sportType == "all" ? "" : sportType }
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results?.length > 0) {
          setFancyList(response_users?.results || []);
        } else {
          setFancyList([]);
        }
      }
    }
  };

  const getFancyData = async (id, selectionId) => {
    const { status, data: message } = await apiGet(apiPath.getFancyOdds, {
      sportType: sportType == "all" ? "" : sportType,
      eventId: id,
    });
    if (status === 200) {
      if (message.success) {
        if (message?.results?.length > 0) {
          let obj = message?.results?.find((res) => {
            return res?.selectionId == selectionId;
          });
          if (!isEmpty(obj)) {
            setFancyOddds(obj);
          }
        }
      }
    }
  };

  const getRiskPremiumData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.riskManagementPremium,
      { sportType: sportType == "all" ? "" : sportType }
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results?.sportBook?.length > 0) {
          setSportBookList(response_users?.results?.sportBook || []);
        } else {
          setSportBookList([]);
        }
        if (response_users?.results?.premiumCricket?.length > 0) {
          setPremiumCricketList(response_users?.results?.premiumCricket || []);
        } else {
          setPremiumCricketList([]);
        }
      }
    }
  };

  const getOdds = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds + `?marketId=${id?.join(",")}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results?.length > 0) {
          setBetFairData((current) =>
            current.map((obj) => {
              let newObj = response_users?.results?.find((res) => {
                return res?.mi == obj?.marketId;
              });
              if (obj?.marketId == newObj?.mi) {
                const back_odds = newObj.rt.filter((rt) => rt.ib) || [];
                const lay_odds = newObj.rt.filter((rt) => !rt.ib) || [];
                return { ...obj, back_odds: back_odds, lay_odds: lay_odds };
              }
              return obj;
            })
          );
        }
      }
    }
  };

  const getOddsBookmaker = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.bookmakerOdds + `?eventId=${id?.join(",")}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results?.length > 0) {
          setBookmakerData((current) =>
            current.map((obj) => {
              let newObj = response_users?.results?.find((res) => {
                return res?.mi == obj?.eventId;
              });
              if (obj?.eventId == newObj?.mi) {
                const back_odds = newObj?.rt?.filter((rt) => rt.ib) || [];
                const newLayOdds = newObj?.rt?.filter((rt) => !rt.ib) || [];
                return { ...obj, back_odds: back_odds, lay_odds: newLayOdds };
              }
              return obj;
            })
          );
        }
      }
    }
  };
  useEffect(() => {
    if (!isEmpty(user)) {
      getMatchData();
      getTopAmount();
      getRiskData();
      if (sportType == "4") {
        getRiskFancyData();
      } else {
        setFancyList([]);
      }
      getRiskPremiumData();
      getRiskDataBookmaker();
    }
  }, [user, sportType]);

  useEffect(() => {
    const intervalID = setInterval(() => {
      if (!isEmpty(user)) {
        getMatchData();
        getTopAmount();
        getRiskData();
        if (sportType == "4") {
          getRiskFancyData();
        } else {
          setFancyList([]);
        }

        getRiskPremiumData();
        getRiskDataBookmaker();
      }
    }, 10 * 1000);

    return () => clearInterval(intervalID);
  }, [user, sportType]);

  return (
    <section className="main-inner-outer py-4 p-4">
      <Container fluid>
        <div className="inner-wrapper">
          <div className="common-container">
            <div className="top_player-wrap mb-4 w-80">
              <h2 className="common-heading">Risk Management Summary</h2>
              <div
                style={{
                  background: "#E0E6E6",
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                  marginBottom: "10px",
                }}
              >
                <Button
                  style={{
                    marginRight: "10px",
                    width: "120px",
                  }}
                  onClick={() => setSportType("4")}
                  className={
                    sportType == "4"
                      ? "green-btn p-1 sportType-active"
                      : "green-btn p-1"
                  }
                >
                  Cricket
                </Button>
                <Button
                  onClick={() => setSportType("2")}
                  style={{
                    marginRight: "10px",
                    width: "120px",
                  }}
                  className={
                    sportType == "2"
                      ? "green-btn p-1 sportType-active"
                      : "green-btn p-1"
                  }
                >
                  Tennis
                </Button>
                <Button
                  onClick={() => setSportType("1")}
                  style={{
                    marginRight: "10px",
                    width: "120px",
                  }}
                  className={
                    sportType == "1"
                      ? "green-btn p-1 sportType-active"
                      : "green-btn p-1"
                  }
                >
                  Soccer
                </Button>
                <Button
                  onClick={() => setSportType("all")}
                  style={{
                    marginRight: "10px",
                    width: "120px",
                  }}
                  className={
                    sportType == "all"
                      ? "green-btn p-1 sportType-active"
                      : "green-btn p-1"
                  }
                >
                  All
                </Button>
              </div>
              <div className="match-sec-sroller">
                <ul className="p-0 mb-0">
                  <li
                    onClick={() => {
                      setHeaderTab("amount");
                    }}
                  >
                    <a
                      className={headerTab == "amount" ? "active" : ""}
                      style={{ cursor: "pointer" }}
                    >
                      Top 20 Matched Amount Player
                    </a>
                  </li>
                  <li
                    onClick={() => {
                      setHeaderTab("exposure");
                    }}
                  >
                    <a
                      className={headerTab == "exposure" ? "active" : ""}
                      style={{ cursor: "pointer" }}
                    >
                      Top 20 Exposure Player
                    </a>
                  </li>
                </ul>

                <Row className="gx-0">
                  <Col md={6}>
                    <div className="account-table bg-white h-100">
                      <div className="responsive">
                        <Table>
                          <thead>
                            <tr>
                              <th>UID</th>
                              <th>Exposure</th>
                              <th>Matched Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(headerTab == "amount"
                              ? topData?.matched
                              : topData?.exposure
                            )?.length > 0 &&
                              (headerTab == "amount"
                                ? topData?.matched
                                : topData?.exposure
                              )
                                ?.slice(0, 10)
                                .map((res, index) => {
                                  return (
                                    <tr key={index + 1}>
                                      <td>
                                        <Link
                                      style={{textDecoration:`none`}}
                                          to={`/currentBets/${res._id}/${res.userType}`}
                                        >
                                          {index + 1}{" "}
                                          <a href="#"   style={{textDecoration:`none`}}>{res.username}</a>
                                        </Link>
                                      </td>
                                      <td>
                                        <strong className="text-danger">
                                          ({" "}
                                          {helpers.currencyFormat(res.exposure)}
                                          ){" "}
                                        </strong>
                                      </td>
                                      <td>
                                        {helpers.currencyFormat(
                                          res.totalAmount
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                            {isEmpty(
                              headerTab == "amount"
                                ? topData?.matched
                                : topData?.exposure
                            ) ? (
                              <tr>
                                <td colSpan={9}>No records found</td>
                              </tr>
                            ) : null}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Col>
                  {(headerTab == "amount"
                    ? topData?.matched
                    : topData?.exposure
                  )?.length > 11 && (
                    <Col md={6}>
                      <div className="account-table bg-white h-100 border_left">
                        <div className="responsive">
                          <Table>
                            <thead>
                              <tr>
                                <th>UID</th>
                                <th>Exposure</th>
                                <th>Matched Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(headerTab == "amount"
                                ? topData?.matched
                                : topData?.exposure
                              )?.length > 0 &&
                                (headerTab == "amount"
                                  ? topData?.matched
                                  : topData?.exposure
                                )
                                  ?.slice(11, 21)
                                  ?.map((res, index) => {
                                    return (
                                      <tr key={index + 1}>
                                        <td>
                                          <Link
                                          style={{textDecoration:`none`}}
                                            to={`/currentBets/${res._id}/${res.userType}`}
                                          >
                                            {index + 6}{" "}
                                            <a href="#" style={{textDecoration:`none`}}>{res.username}</a>
                                          </Link>
                                        </td>
                                        <td>
                                          <strong className="text-danger">
                                            (
                                            {helpers.currencyFormat(
                                              res.exposure
                                            )}
                                            ){" "}
                                          </strong>
                                        </td>
                                        <td>
                                          {helpers.currencyFormat(
                                            res.totalAmount
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              {isEmpty(
                                headerTab == "amount"
                                  ? topData?.matched
                                  : topData?.exposure
                              ) ? (
                                <tr>
                                  <td colSpan={9}>No records found</td>
                                </tr>
                              ) : null}
                              {topData?.matched?.length > 10 && (
                                <tr>
                                  <Button
                                    onClick={() => navigate("/matchedAll")}
                                    className="green-btn mt-2 p-1"
                                  >
                                    View All
                                  </Button>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </div>
            {betFaireDataCheck && bookmakerDataCheck ? (
              <>
                {betFairData && betFairData?.length > 0 && (
                  <BetTypeListing title={"Match Odds"} data={betFairData} />
                )}
                {bookmakerData && bookmakerData?.length > 0 && (
                  <BetTypeListing title={"Book Maker"} data={bookmakerData} />
                )}
                {sportType == "4" && (
                  <FancyBetTypeListingNew
                    title={"Fancy Bet"}
                    data={fancyList ? fancyList : []}
                    getFancyData={getFancyData}
                    fancyOdds={fancyOdds}
                    setFancyOddds={setFancyOddds}
                  />
                )}
              </>
            ) : (
              <div
                id="loader"
                className="spinner"
                style={{ display: `block` }}
              ></div>
            )}{" "}
            {sportBookList?.length > 0 && (
              <SportBookListing
                title={"Sports Book"}
                data={sportBookList ? sportBookList : []}
              />
            )}
            <PremiumListing
              title={"Premium Cricket"}
              data={premiumCricketList ? premiumCricketList : []}
            />
            <BinaryListing
              title={"Binary"}
              data={fancyList ? fancyList : []}
              matchName={matchName}
              detailsData={detailsData}
            />
          </div>
        </div>
      </Container>
    </section>
  );
};

export default RiskManagement;
