import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { apiGet } from "../../utils/apiFetch";
import apiPath from "../../utils/apiPath";
import constants from "../../utils/constants";
import { useEffect } from "react";
import obj from "../../utils/constants";
import { isEmpty } from "lodash";
import helpers from "../../utils/helpers";
const FancyBetDialog = () => {
  const { name, eventid, marketId, selectionId } = useParams();
  const params = useParams();

  const [matchData, setMatchData] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [search_params, setSearchParams] = useState({
    eventId: eventid,
    selectionId: selectionId,
    marketId: marketId,
  });

  const getDetails = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.fancyPosition,
      search_params
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setDetailsData(response_users.results);
        }
      }
    }
  };
  useEffect(() => {
    getDetails();
  }, [search_params]);
  return (
    <section className="main-inner-outer py-4 pop-warp-fancy">
      <section className="account-table" style={{maxWidth: `550px`,width:`100%`, height: `800px`,margin:`auto`,boxShadow: `0 4px 5px rgba(0,0,0,0.5)`,overflow:`auto`}}>
        <div className="container-fluid">

        <div className="db-sec d-flex justify-content-between align-items-center mb-2 odds-game-team">
            <h2 className="">
             <span>{params.name}</span>
            </h2>
            <Button className="green-btn" onClick={() => window.close()}>
             X Close
            </Button>
          </div>

          
          <div className="responsive">
            <h2 className="common-heading">Run Position</h2>
            <Table style={{width:`100%`}} class="table01">
              <thead>
                <tr>
                  <th scope="col">Run</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                {detailsData.length > 0 &&
                  detailsData.map((res, index) => {
                    return (
                      <>
                        <tr>
                          
                          <td style={res.position>=0?{ background: "#fae5eb"}:{ background: "#cde8fd"}}>
                            {res.betRun}
                          </td>
                          <td
                            style={res.position>=0?{ background: "#fae5eb", color: "#d0021b"}:{ background: "#cde8fd" }}>
                            {res.position>=0?`${"-"+helpers.currencyFormat(res.position)}`:`(${helpers.currencyFormat(Math.abs(res.position))})`}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                {isEmpty(detailsData) ? (
                  <tr>
                    <td colSpan={9}>No records found</td>
                  </tr>
                ) : null}
              </tbody>
            </Table>
          </div>
        </div>
      </section>
    </section>
  );
};

export default FancyBetDialog;
