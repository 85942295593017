import { isEmpty } from "lodash";
import React, { useState, useContext, useRef, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import AuthProvider from "../context/AuthContext";
import { apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import styles from "../assets/newCss/sky.css";
import { Helmet } from "react-helmet";
import Aboutus from "../components/staticContent/Aboutus";
import GeneralTerms from "../components/staticContent/GeneralTerms";
import Kyc from "../components/staticContent/Kyc";
import PrivacyPolicy from "../components/staticContent/PrivacyPolicy";
import RulesRegulation from "../components/staticContent/RulesRegulation";
import ResponsibleGaming from "../components/staticContent/ResponsibleGaming";
import { ImEye ,ImEyeBlocked  } from "react-icons/im";
function LoginMobile(props) {
  let {
    loginUser,
    isLoged,
    user,
    open,
    setOpen,
    content,
    slug,
    setSlug,
    handleContentToggle,
  } = useContext(AuthProvider);

  const navigate = useNavigate();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    unregister,
    reset: reset2,
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      uniqueId: Math.random() * 10000,
    },
  });
  const [getValidateCode, setValidateCode] = useState("");
  const [data, setData] = useState({});
  const onSubmit2 = (data) => {
    // if (parseInt(data.validateCode) === parseInt(getValidateCode)) {
      loginUser(data);
    // }
  };
   const canvasRef = useRef(null);
  // const changeCode = () => {
  //   const canvas = canvasRef.current;
  //   const context = canvas.getContext("2d");
  //   context.font = "italic bold 150px serif";
  //   const code = Math.floor(1000 + Math.random() * 9000);
  //   context.clearRect(0, 0, canvas.width, canvas.height);
  //   context.fillText(code,50, 140,200);
  //   setValidateCode(code);
  // };
  // const changeCode = () => {
  //   const canvas = canvasRef.current;
  //   const context = canvas.getContext("2d");
  //   context.font = "italic bold 150px serif";
  //   const code = Math.floor(1000 + Math.random() * 9000);
  //   context.clearRect(0, 0, canvas.width, canvas.height);
  //   context.fillText(code, 0, 130,300);
  //   setValidateCode(code);
  // };
  const getSetting = async (event) => {
    try {
      const { status, data: response_users } = await apiGet(apiPath.getSetting);
      if (status === 200) {
        if (response_users.success) {
          setData(response_users.results);
        }
      }
    } catch (err) { }
  };
  useEffect(() => {
    // changeCode();
    getSetting();
  }, []);
  useEffect(() => {
    if (user !== null) {
      navigate("/");
    }
  }, [user]);
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword =(e)=>{
    e.preventDefault()
    if(passwordType==="password")
    {
     setPasswordType("text")
     return;
    }
    setPasswordType("password")
  }
  return (
    <div className="newLogin">
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale = 1.0, user-scalable = no"
        />
      </Helmet>

      <div className="login_main" style={{height: `100vh`}}>
        <header className="login-head">
          {/* <Link to="/" className="close"></Link> */}
          {/* <h1></h1> */}
          {/* <div id="powerWrap" className="betfair-wrap">
            <p>Powered by</p>
            <span>
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "/assets/images/betfair_black.png"
                }
                alt=""
                draggable="false"
              />
            </span>
          </div> */}
        </header>
        <Form onSubmit={handleSubmit2(onSubmit2)}>
          <dl className="form-login">
            <Form.Group
              style={{ marginBottom: "10px" }}
              id="loginNameErrorClass"
            >
              <Form.Control
                type="text"
                autocomplete="off"
                // id="loginName"
                className={errors2.username ? "is-invalid" : ""}
                {...register2("username", {
                  required: "Please enter Username",
                })}
                placeholder="Username"
               
              />
              {errors2.username && errors2?.username?.message && (
                <label className="invalid-feedback text-left">
                  {errors2.username.message}
                </label>
              )}
            </Form.Group>
            <Form.Group className="mb-2">
                    <div style={{ position:'relative'}}>
                      <Form.Control
                         type={passwordType ?passwordType:"password"}
                        placeholder="Password"
                        className={errors2.password ? "is-invalid" : ""}
                        {...register2("password", {
                          required: "Please enter password",
                        })}
                      />
                       <div className="input-group-btn" style={{position:'absolute', right:'0', top:'-5px', bottom:'0px'}}>
                     <span className="btn btn-outline-primary" onClick={togglePassword} style={{color:` #000`,fontSize:`20px`,backgroundColor:'transparent', border:'0',padding:'8px', paddingTop:'3px', height:'72%',filter: `brightness(0.5)`}}>
                     { passwordType==="password"?<ImEyeBlocked /> :  <ImEye/>}
                      </span>
                      </div>
                      </div>
                      {errors2.password && errors2.password.message && (
                        <label className="invalid-feedback text-left">
                          {errors2.password.message}
                        </label>
                      )}
                    </Form.Group>
            {/* <dd id="validCodeErrorClass" style={{ display: "block" }}>
              <input
                type="number"
                keyboardType="numeric"
                autocomplete="off"
                maxLength="4"
                className={errors2.validateCode ? "is-invalid" : ""}
                {...register2("validateCode", {
                  required: "Please enter validate code",
                  validate: {
                    validate: (value) =>
                      parseInt(value) === parseInt(getValidateCode) ||
                      "Invalid validate code",
                  },
                })}
                onChange={(e) => {
                  if (e.target.value.length == 4) {
                    e.target.blur();
                    unregister("validateCode", { keepValue: true });
                  }
                }}
             
                id="validCode"
                placeholder="Captcha"
                style={{padding: `2.66667vw 1.86667vw`}}
             
              />
           
              <canvas
                ref={canvasRef}
                onClick={changeCode}
                className="inputcanvas"
                id="authenticateImage"
              />

              {errors2.validateCode && errors2.validateCode.message && (
                <label className="invalid-feedback text-left">
                  {errors2.validateCode.message}
                </label>
              )}
            </dd> */}
            
            <dd>
              <Button type="submit" className="btn-send w-100" id="loginBtn">
                Login
              </Button>
            </dd>
            {isLoged == "failed" && (
              <dd id="errorMsg" class="state">
                Login name or password is invalid! Please try again.
              </dd>
            )}
          </dl>
        </Form>

       
        <dl class="form-login-link" >
          <button id="appDesign"  type="button" class="download-btns app_store downloadappbtn">
          <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M270.1 741.7c0 23.4 19.1 42.5 42.6 42.5h48.7v120.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V784.1h85v120.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V784.1h48.7c23.5 0 42.6-19.1 42.6-42.5V346.4h-486v395.3zm357.1-600.1l44.9-65c2.6-3.8 2-8.9-1.5-11.4-3.5-2.4-8.5-1.2-11.1 2.6l-46.6 67.6c-30.7-12.1-64.9-18.8-100.8-18.8-35.9 0-70.1 6.7-100.8 18.8l-46.6-67.5c-2.6-3.8-7.6-5.1-11.1-2.6-3.5 2.4-4.1 7.4-1.5 11.4l44.9 65c-71.4 33.2-121.4 96.1-127.8 169.6h486c-6.6-73.6-56.7-136.5-128-169.7zM409.5 244.1a26.9 26.9 0 1 1 26.9-26.9 26.97 26.97 0 0 1-26.9 26.9zm208.4 0a26.9 26.9 0 1 1 26.9-26.9 26.97 26.97 0 0 1-26.9 26.9zm223.4 100.7c-30.2 0-54.6 24.8-54.6 55.4v216.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V400.1c.1-30.6-24.3-55.3-54.6-55.3zm-658.6 0c-30.2 0-54.6 24.8-54.6 55.4v216.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V400.1c0-30.6-24.5-55.3-54.6-55.3z"></path>
          
          <animate
            attributeType="XML"
            attributeName="fill"
            values="#FF0000;#ff0;#30f19c"
            dur="0.8s"
            repeatCount="indefinite"/>
            </svg>
        <p>| DOWNLOAD APP</p>
        </button>
        <button id="links"  type="button" class="download-btns app_store alternatelinksbtns"
       
      >
        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M574 665.4a8.03 8.03 0 0 0-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 0 0-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 0 0 0 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 0 0 0 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 0 0-11.3 0L372.3 598.7a8.03 8.03 0 0 0 0 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z"></path>
       
        <animate
            attributeType="XML"
            attributeName="fill"
            values="#FF0000;#ff0;#30f19c"
            dur="0.8s"
            repeatCount="indefinite"/>
             </svg>
        <p>| VIEW ALL LINKS</p>
        </button>
        </dl>

        <div id="supportWrap" className="support-wrap mb-0 pb-0 login-support">
          <div className="support-service">
            <Link
              id="support_whatsapp"
              to="javascript:void(0)"
              onClick={() => setOpen("tab1")}
              className={open == "tab1" ? "support-mail open" : "support-mail "}
              // className="support-whatsapp open"
            >
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "assets/images/transparent.gif"
                }
                title="WhatsApp"
                alt=""
                className="support-mail"
              />
            </Link>
            <Link
              id="support_facebook"
              to="javascript:void(0)"
              onClick={() => setOpen("tab2")}
              className={
                open == "tab2" ? "support-whatsapp open" : "support-whatsapp "
              }
            >
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "assets/images/transparent.gif"
                }
                alt=""
                title="WhatsApp"
                className="support-whatsapp"
              />
            </Link>

            <Link
              id="support_facebook"
              to="javascript:void(0)"
              onClick={() => setOpen("tab3")}
              className={
                open == "tab3" ? "support-telegram open" : "support-telegram "
              }
            >
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "assets/images/transparent.gif"
                }
                alt=""
                title="WhatsApp"
                className="support-telegram"
              />
            </Link>

            <Link
              id="support_facebook"
              to="javascript:void(0)"
              onClick={() => setOpen("tab4")}
              className={
                open == "tab4"
                  ? "support-skype open"
                  : "support-skype text-white"
              }
            >
              <img
                src={
                  process.env.REACT_APP_IMAGE_URL +
                  "assets/images/transparent.gif"
                }
                alt=""
                title="Skype"
                className="support-skype"
              />
            </Link>
          </div>

          {/* <div class="support-info">
            {open == "tab1" && (
              <div>
                <Link
                  id="emailContent"
                  className="ui-link text-white"
                  target="_blank"
                  to="mailto:https://betxwin.live"
                >
                  https://betxwin.live
                </Link>
              </div>
            )}
            {open == "tab2" && (
              <div>
                <div>
                  <Link
                    id="emailContent"
                    className="ui-link text-white"
                    target="_blank"
                    to="mailto:https://betxwin.live"
                  >
                    https://betxwin.live
                  </Link>
                </div>
              </div>
            )}
            {open == "tab3" && (
              <div>
                <div>
                  <Link
                    id="emailContent"
                    className="ui-link text-white"
                    target="_blank"
                    to="mailto:https://betxwin.live"
                  >
                    https://betxwin.live
                  </Link>
                </div>
              </div>
            )}
            {open == "tab4" && (
              <div>
                <div>
                  <Link
                    id="emailContent"
                    className="ui-link text-white"
                    target="_blank"
                    to="mailto:https://betxwin.live"
                  >
                    https://betxwin.live
                  </Link>
                </div>
              </div>
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default LoginMobile;
