import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Table,
  Button,
  Modal,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "wc-toast";
import { apiGet, apiPost } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import Select from "react-select";
import obj from "../utils/constants";
import { Link } from "react-router-dom";
import { isEmpty, startCase } from "lodash";
import helpers from "../utils/helpers";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
const LiveMatchBet = () => {
  const [data, setData] = useState([]);
  const [unmatched, setUnmatched] = useState("");
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState(true);
  const [keyword, setKeyword] = useState();
  const [getBetType, setBetType] = useState("betfair");
  const [typeSelect2, setTypeSelect2] = useState([]);
  const [typeSelect3, setTypeSelect3] = useState([]);
  const [select, setSelect] = useState("event");
  const [listEvents, setListEvents] = useState([]);
  const [updatedListEvent, setUpdatedListEvent] = useState([]);
  const [updatedListMarket, setUpdatedListMarket] = useState([]);
  const [listMarket, setListMarket] = useState([]);
  const [fancyData, setFancyData] = useState([]);
  const [filter, setFilter] = useState({
    type: "4",
    betType: "betfair",
    sortType: "amount",
    sortOrder: "asc",
    last: 25,
    status: "active",
  });

  const [betId, setBetId] = useState("");
  const [getStatus, setStatus] = useState("");
  const [id, setId] = useState("");
  const handleClick = () => {
    getData();
  };

  const handleClickRemove = () => {
    setKeyword("");
    getData();
  };

  const setOpenToggle = (betId, eventId, userId, marketId, status, type) => {
    setOpen(!open);
    setBetId({ betId, eventId, userId, type, marketId });
    setStatus(status);
  };

  const getData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.getSettingBetslive,
      { ...filter, keyword: keyword ? keyword : "" }
    );
    if (status === 200) {
      if (response_users.success) {
        setCheck(true);
        setBetType(filter?.betType);
        let temp =
          response_users?.results &&
          response_users?.results.length > 0 &&
          response_users.results.filter((res) => !res.isMatched);
        setUnmatched(temp);
        setData(response_users.results);
      }
    }
  };
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    reset: reset1,
  } = useForm({});
  const [isLoader, setLoader] = useState(false);
  const onSubmit1 = async (request) => {
    setLoader(true);
    let obj = {};
    if (betId?.type == "multi") {
      let selected = data?.filter((res) => {
        return res?.select;
      });
      obj = {
        betId: selected
          ?.map((res) => {
            return res?.matchBetId || res?.sessionBetId || res?.platformTxId;
          })
          .join(","),
        eventId: selected
          ?.map((res) => {
            return res?.eventId;
          })
          .join(","),
        userId: selected
          ?.map((res) => {
            return res?.userId;
          })
          .join(","),
        marketId: selected
          ?.map((res) => {
            return res?.marketId;
          })
          .join(","),
        status: getStatus,
        betType: getBetType,
        multi: true,
        // password: request.password,
        pageType: "liveMatchBet",
      };
    } else {
      obj = {
        betId: betId?.betId,
        eventId: betId?.eventId,
        userId: betId?.userId,
        status: getStatus,
        betType: getBetType,
        marketId: betId?.marketId,
        password: request.password,
        pageType: "liveMatchBet",
      };
    }
    try {
      if ((betId && getStatus, getBetType)) {
        const { status, data: response_users } = await apiPost(
          apiPath.updateBetsStatus,
          obj
        );
        if (status === 200) {
          if (response_users.success) {
            setLoader(false);
            toast.success(response_users.message);
            getData();
            reset1();
            setOpenToggle();
          } else {
            setLoader(false);
            toast.error(response_users.message);
          }
        }
      }
    } catch (err) {
      setLoader(false);
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  let length =
    data?.length > 0
      ? data?.filter((res) => {
          return res?.select;
        })?.length
      : 0;

  const getCompetion = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.matchFilterList
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users?.results?.length > 0) {
          let list = response_users?.results?.map((res) => {
            return {
              label: `${startCase(res?.gameType)} > ${startCase(
                res?.eventName
              )}`,
              value: res,
            };
          });
          let market = response_users?.results?.map((res) => {
            return {
              label: `${startCase(res?.gameType)} > ${startCase(
                res?.eventName
              )} > ${startCase(res?.market)}`,
              value: res,
            };
          });
          setListEvents(list);
          setListMarket(market);
          setUpdatedListEvent(
            list?.filter((res) => {
              return res?.value?.eventType == filter?.type;
            })
          );
          setUpdatedListMarket(
            market?.filter((res) => {
              return res?.value?.eventType == filter?.type;
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    setUpdatedListEvent(
      listEvents?.filter((res) => {
        return res?.value?.eventType == filter?.type;
      })
    );
    setUpdatedListMarket(
      listMarket?.filter((res) => {
        return res?.value?.eventType == filter?.type;
      })
    );
  }, [filter?.type]);

  useEffect(() => {
    getCompetion();
  }, []);

  const ObjSelect = {
    sportBook: updatedListEvent,
    fancy: updatedListEvent,
    event: updatedListEvent,
    market: updatedListMarket,
  };
  const handelChangeTypeSelect2 = (e, select) => {
    setTypeSelect2(e);
    setFilter((prev) => {
      return { ...prev, eventId: e?.value?.eventId };
    });
    if (select == "fancy" || select == "sportBook") {
      handleSearch(e?.value?.eventId);
      setTypeSelect3([]);
    }
  };

  const handleSearch = async (eventId) => {
    let obj = {
      eventId: eventId,
    };
    const { status, data: response_users } = await apiGet(
      apiPath.resultFancyPremiumList,
      obj
    );
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results?.pendingFancy) {
          let data = response_users.results?.pendingFancy?.filter((res) => {
            return select == "fancy"
              ? res?.fancyType == "fancy"
              : res?.fancyType == "premium";
          });
          setFancyData(
            data?.map((res) => {
              return {
                label: res?.fancyName,
                value: res,
              };
            })
          );
        }
      }
    }
  };
  return (
    <div>
      <section className="main-inner-outer py-4">
        <Container fluid>
          <Form className="bet_status bet-list-live mb-4 livemachbetstatus">
            <div key={`inline-radio`} className="mb-1">
              {obj.betCheckData3.map((type) => {
                return (
                  <Form.Check
                    inline
                    label={type.value}
                    name={type.label}
                    type="radio"
                    checked={type.label === filter.type ? true : false}
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        type: e.target.name,
                        betType:
                          type?.value == "Casino"
                            ? "casino"
                            : type?.value == "Parlay"
                            ? "parlay"
                            : "betfair",
                      });
                      setTypeSelect2([]);
                      setTypeSelect3([]);
                      setSelect("event");
                    }}
                    id={`inline-radio-1`}
                  />
                );
              })}
            </div>
            {!["3", "parlay"].includes(filter.type) && (
              <div key={`inline-radio`} className="mb-2">
                {obj.betCheckDataInner.map((type) => {
                  return (
                    <Form.Check
                      inline
                      label={type.value}
                      name={type.label}
                      type="radio"
                      checked={type.label === filter.betType ? true : false}
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          betType: e.target.name,
                        });
                        if (type?.value == "Fancy") {
                          setSelect("fancy");
                        } else if (type?.value == "SportsBook") {
                          setSelect("sportBook");
                        }
                        setTypeSelect2([]);
                        setTypeSelect3([]);
                      }}
                      id={`inline-radio-1`}
                    />
                  );
                })}
              </div>
            )}
            <div className="d-flex align-items-center mt-2 mb-2">
              {(filter?.betType == "betfair" ||
                filter?.betType == "bookmaker") && (
                <>
                  <div
                    className="d-flex align-items-center"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      setSelect("event");
                      setTypeSelect2([]);
                      setTypeSelect3([]);
                    }}
                  >
                    {" "}
                    <input
                      autocomplete="off"
                      type="radio"
                      name="typeWise"
                      checked={select == "event"}
                    />
                    <label
                      style={{ fontWeight: "600", marginLeft: "6px" }}
                      for="event"
                    >
                      Event Wise
                    </label>
                  </div>
                  {/* <div
                    className="d-flex align-items-center"
                    onClick={() => {
                      setSelect("market");
                      setTypeSelect2([]);
                      setTypeSelect3([]);
                    }}
                  >
                    {" "}
                    <input
                      autocomplete="off"
                      type="radio"
                      name="typeWise"
                      checked={select == "market"}
                    />
                    <label
                      style={{ fontWeight: "600", marginLeft: "6px" }}
                      for="market"
                    >
                      Market Wise
                    </label>
                  </div>{" "} */}
                </>
              )}

              {filter?.betType == "fancy" && (
                <div
                  className="d-flex align-items-center"
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    setSelect("fancy");
                    setTypeSelect2([]);
                  }}
                >
                  {" "}
                  <input
                    autocomplete="off"
                    type="radio"
                    name="typeWise"
                    checked={select == "fancy"}
                  />
                  <label
                    style={{ fontWeight: "600", marginLeft: "6px" }}
                    for="fancy"
                  >
                    Fancy Wise
                  </label>
                </div>
              )}
              {filter?.betType == "sportBook" && (
                <div
                  className="d-flex align-items-center"
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    setSelect("sportBook");
                    setTypeSelect2([]);
                  }}
                >
                  {" "}
                  <input
                    autocomplete="off"
                    type="radio"
                    name="typeWise"
                    checked={select == "sportBook"}
                  />
                  <label
                    style={{ fontWeight: "600", marginLeft: "6px" }}
                    for="sportBook"
                  >
                    SportBook Wise
                  </label>
                </div>
              )}
            </div>
            {!["3", "parlay"].includes(filter.type) && (
              <div className="mb-2 d-flex align-items-center">
                <div className="w-25" style={{ marginRight: "10px" }}>
                  <Select
                    className="basic-single"
                    placeholder={`Select ${startCase(select)}`}
                    isClearable={true}
                    isSearchable={true}
                    onChange={(e) => {
                      handelChangeTypeSelect2(e, select);
                    }}
                    options={ObjSelect[select]}
                    value={typeSelect2}
                  />
                </div>
                {(select == "fancy" || select == "sportBook") &&
                  fancyData?.length > 0 && (
                    <div className="w-25 ">
                      <Select
                        className="basic-single"
                        placeholder={`Select ${startCase(select)} Name`}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(e) => {
                          setFilter((prev) => {
                            return {
                              ...prev,
                              selectionId: e?.value?.selectionId,
                            };
                          });
                          setTypeSelect3(e);
                        }}
                        options={fancyData}
                        value={typeSelect3}
                      />
                    </div>
                  )}
              </div>
            )}

            <Row className="mb-2">
              <Col xxl={2} lg={3} md={4} sm={6} className="mb-lg-0 mb-3">
                <div className="bet-sec">
                  <Form.Label>Order of display:</Form.Label>
                  <Form.Select
                    onChange={(e) =>
                      setFilter({ ...filter, sortType: e.target.value })
                    }
                    value={filter.sortType}
                    aria-label="Default select example"
                  >
                    <option value="amount">Stake</option>
                    <option value="timeInserted">Time</option>
                  </Form.Select>
                </div>
              </Col>
              <Col xxl={2} lg={3} md={4} sm={6} className="mb-lg-0 mb-3">
                <div className="bet-sec bet-period">
                  <Form.Label>of</Form.Label>
                  <Form.Select
                    onChange={(e) =>
                      setFilter({
                        ...filter,
                        sortOrder: e.target.value,
                      })
                    }
                    value={filter.sortOrder}
                    aria-label="Default select example"
                  >
                    <option value="asc">Ascending</option>
                    <option value="desc">Decending</option>
                  </Form.Select>
                </div>
              </Col>

              <Col xxl={2} lg={3} md={4} sm={6} className="mb-lg-0 mb-3">
                <div className="bet-sec bet-period">
                  <Form.Label>Last</Form.Label>
                  <Form.Select
                    onChange={(e) =>
                      setFilter({ ...filter, last: e.target.value })
                    }
                    value={filter.last}
                    aria-label="Default select example"
                  >
                    <option value={25}>25 Txn</option>
                    <option value={50}>50 Txn</option>
                    <option value={100}>100 Txn</option>
                    <option value={200}>200 Txn</option>
                    <option value={""}>All</option>
                  </Form.Select>
                </div>
              </Col>
              <Col xxl={2} lg={3} md={4} sm={6} className="mb-lg-0 mb-3">
                <div className="bet-sec">
                  <Form.Label>Bet Status:</Form.Label>
                  <Form.Select
                    onChange={(e) =>
                      setFilter({ ...filter, status: e.target.value })
                    }
                    value={filter.status}
                    aria-label="Default select example"
                  >
                    <option value={"active"}>Active</option>
                    {/* <option value={"suspend"}>Suspend</option>{" "} */}
                    <option value={"suspend"}>Voided</option>
                  </Form.Select>
                </div>
              </Col>

              <Col lg={1}>
                <div style={{ display: "flex" }}>
                  <Button
                    className="green-btn"
                    style={{ marginRight: "10px" }}
                    onClick={() => getData("search")}
                  >
                    Search
                  </Button>
                  <Button
                    className="theme_light_btn"
                    onClick={() => {
                      setFilter({
                        type: "4",
                        betType: "betfair",
                        sortType: "amount",
                        sortOrder: "",
                        last: 25,
                        status: "active",
                      });
                      getData({
                        type: "4",
                        betType: "betfair",
                        sortType: "amount",
                        sortOrder: "",
                        last: 25,
                        status: "active",
                      });
                      setTypeSelect2([]);
                      setTypeSelect3([]);
                      setSelect("event");
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col lg={4} sm={3} className="mb-lg-0 mb-3">
                <div className="bet-sec bet-period">
                  <Form.Label className="mt-2 me-2">From</Form.Label>
                  <Form.Group className="form-group">
                    <Form.Control
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          fromPeriod: e.target.value,
                          filterByDay: "",
                        })
                      }
                      max={new Date().toISOString().split("T")[0]}
                      value={filter.fromPeriod}
                      type="date"
                    />
                    <Form.Control
                      className="small_form_control w-25"
                      type="time"
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          fromPeriodTime: e.target.value,
                        })
                      }
                      placeholder="00:00"
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col lg={4} sm={3} className="mb-lg-0 mb-3">
                <div className="bet-sec bet-period">
                  <Form.Label className="mt-2 me-2">To</Form.Label>
                  <Form.Group className="form-group">
                    <Form.Control
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          toPeriod: e.target.value,
                          filterByDay: "",
                        })
                      }
                      min={
                        filter?.fromPeriod
                          ? new Date(filter?.fromPeriod)
                              .toISOString()
                              .split("T")[0]
                          : new Date()
                      }
                      disabled={filter.fromPeriod ? false : true}
                      max={new Date().toISOString().split("T")[0]}
                      value={filter.toPeriod}
                      type="date"
                    />
                    <Form.Control
                      className="small_form_control w-25"
                      type="time"
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          toPeriodTime: e.target.value,
                        })
                      }
                      placeholder="23:59"
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row> */}
          </Form>

          <div className="find-member-sec">
            <Row className="mb-3">
              <Col md={6}>
                <Form className="">
                  <Form.Group className="position-relative mb-2">
                    <Form.Control
                      type="text"
                      className="small_form_control"
                      placeholder="Enter Username..."
                      onChange={(e) => {
                        setKeyword(e.target.value);
                      }}
                    />
                    <i className="fas fa-search"></i>
                  </Form.Group>
                  <div className="d-flex flex-wrap block-search-sec ms-sm-3">
                    {/* <Button className="mb-2 mx-1 theme_dark_btn">Search1</Button> */}
                    <Button
                      className="mb-2 mx-1 theme_dark_btn"
                      onClick={handleClick}
                    >
                      Search
                    </Button>
                    <Button
                      className="mb-2 mx-1 theme_dark_btn"
                      onClick={handleClickRemove}
                    >
                      Clear
                    </Button>
                  </div>
                </Form>
              </Col>
              <Col md={6} className="">
                <div className="d-flex flex-wrap live-match-bat justify-sm-content-end">
                  <Link
                    to="/userProfitLoss"
                    className="mb-2 mx-1 theme_dark_btn green-btn"
                  >
                    User P/L
                  </Link>
                  <Link
                    to="/PreMatch"
                    className="mb-2 mx-1 theme_dark_btn green-btn"
                  >
                    Pre-Match P/L
                  </Link>
                  <Link
                    to="/rejected-bets"
                    className="mb-2 mx-1 theme_dark_btn green-btn"
                  >
                    Rejected Bets
                  </Link>
                </div>
              </Col>
            </Row>

            <div className="account-table livematchbet-table">
              <caption className="d-block text-start">Matched</caption>
              <div className="responsive">
                <Table className="banking_detail_table">
                  <thead>
                    {getBetType == "parlay" ? (
                      <tr>
                        <th scope="col">Bet Id </th>
                        <th scope="col">Place Time</th>
                        <th scope="col">IP</th>
                        <th scope="col">Odds</th>
                        <th scope="col">Stake</th>
                        <th scope="col">Type</th>
                        <th scope="col">Profit/Loss</th>
                        <th scope="col">Action</th>
                      </tr>
                    ) : (
                      <tr>
                        <th scope="col">Sports </th>
                        <th scope="col">
                          {getBetType === "casino" ? "Casino" : "Match"} Name
                        </th>
                        <th scope="col">Client</th>
                        <th scope="col">Type</th>
                        <th scope="col">Selection</th>
                        {getBetType !== "casino" && <th scope="col">Odds</th>}
                        <th scope="col">Stake</th>
                        <th scope="col">Place Time</th>
                        <th scope="col">IP</th>
                        {getBetType !== "casino" && <th scope="col">Pnl</th>}
                        { filter?.status !== "suspend" ? (
                          <th scope="col">Action</th>
                        ):""}
                      </tr>
                    )}
                  </thead>
                  {data && data?.length > 0 ? (
                    data?.map((item) => {
                      if (getBetType === "fancy" || getBetType == "sportBook") {
                        return (
                          <tr className={
                            item?.betType == "lay" || item?.type == "No"
                              ? "lay-bg-row"
                              : item?.betType == "back" ||
                                item?.type == "Yes"
                              ? "back-bg-row"
                              : ""
                          }>
                            <td>{obj.betCheckObj[filter.type]}</td>
                            <td>{`${item?.matchName} -> ${item?.eventId} -> ${item?.fancyName}`}</td>
                            <td> {item?.clientName || "N/A"}</td>
                            <td
                              
                            >
                              {item?.type || item?.betType || "N/A"}
                            </td>
                            <td>
                              {" "}
                              {item?.runnrName || item?.runnerName || "N/A"}
                            </td>
                            <td> {item?.betRun || item?.betBhav || "N/A"}</td>
                            <td> {item?.amount || "N/A"}</td>
                            <td> {item?.timeInserted || "N/A"} </td>
                            <td>
                              {" "}
                              {(item?.ipAddress &&
                                item?.ipAddress.replace("::ffff:", "")) ||
                                "N/A"}
                            </td>
                            <td>
                              {" "}
                              {filter?.type == "suspend"
                                ? "-"
                                : item?.profitAmount || "N/A"}{" "}
                            </td>
                            {/* <td> {item?.loseAmount || "N/A"} </td> */}

                            {["active", "pending"]?.includes(item?.status) &&  (
                              
                              <td>
                                <Button
                                  className="theme_light_btn"
                                  onClick={() =>
                                    setOpenToggle(
                                      item.matchBetId,
                                      item?.eventId,
                                      item?.userId,
                                      item?.marketId,
                                      "delete"
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                                <Button
                                  className="theme_light_btn"
                                  onClick={() =>
                                    setOpenToggle(
                                      item.matchBetId,
                                      item?.eventId,
                                      item?.userId,
                                      item?.marketId,
                                      "voided"
                                    )
                                  }
                                >
                                  Voided
                                </Button>{" "}
                              </td>
                            )}
                          </tr>
                        );
                      } else if (getBetType === "casino") {
                        return (
                          <tr>
                            <td>{obj.betCheckObj[filter.type]}</td>
                            <td>{item?.casinoName || "N/A"}</td>
                            <td> {item?.clientName || "N/A"}</td>
                            <td> {item?.gameCode || "N/A"}</td>
                            <td> {item?.platformTxId || "N/A"}</td>
                            <td> {item?.betAmount || "N/A"}</td>
                            <td>
                              {" "}
                              {(item?.timeInserted &&
                                helpers.dateFormat(item.timeInserted)) ||
                                "N/A"}{" "}
                            </td>
                            <td>
                              {" "}
                              {(item?.ipAddress &&
                                item?.ipAddress.replace("::ffff:", "")) ||
                                "N/A"}
                            </td>
                            {/* <td> {item?.profitAmount || "N/A"} </td> */}
                            {/* <td> {item?.loseAmount || "N/A"} </td> */}

                            {["active", "pending"]?.includes(item?.status) && (
                              <td>
                                <Button
                                  className="theme_light_btn"
                                  onClick={() =>
                                    setOpenToggle(
                                      item.matchBetId,
                                      item?.eventId,
                                      item?.userId,
                                      item?.marketId,
                                      "delete"
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                                <Button
                                  className="theme_light_btn"
                                  onClick={() =>
                                    setOpenToggle(
                                      item.matchBetId,
                                      item?.eventId,
                                      item?.userId,
                                      item?.marketId,
                                      "voided"
                                    )
                                  }
                                >
                                  Voided
                                </Button>{" "}
                              </td>
                            )}
                          </tr>
                        );
                      } else if (getBetType == "parlay") {
                        return (
                          <>
                            <tr>
                              <td>
                                <div className="d-flex align-items-center">
                                  {" "}
                                  {item?.matchBetId}{" "}
                                  <div style={{ marginLeft: "10px" }}>
                                    {item?.matchBetId == id ? (
                                      <AiFillMinusCircle
                                        onClick={() => {
                                          setId("");
                                        }}
                                        size={16}
                                        style={{ marginRight: "3px" }}
                                        color="rgba(59, 130, 246, 1)"
                                      />
                                    ) : (
                                      <AiFillPlusCircle
                                        size={16}
                                        onClick={() => {
                                          setId(item?.matchBetId);
                                        }}
                                        style={{ marginRight: "3px" }}
                                        color="rgba(59, 130, 246, 1)"
                                      />
                                    )}
                                  </div>
                                </div>
                              </td>
                              <td> {helpers.dateFormat(item?.timeInserted)}</td>
                              <td>
                                {" "}
                                {(item?.ipAddress &&
                                  item?.ipAddress.replace("::ffff:", "")) ||
                                  "N/A"}{" "}
                              </td>
                              <td>{obj.truncateDecimals(item?.bhav, 2)}</td>
                              <td>{item?.amount}</td>
                              <td>
                                {item?.bets?.length == 1
                                  ? "Single"
                                  : item?.bets?.length == 2
                                  ? "DOUBLE"
                                  : item?.bets?.length > 2
                                  ? "ACCUMULATOR"
                                  : ""}
                              </td>

                              {filter?.status == "suspend" ? (
                                <td>{"-"}</td>
                              ) : (
                                <td>
                                  {" "}
                                  {item?.isDeclared ? (
                                    <td>
                                      {item?.result == "win" ? (
                                        <span className="text-success">
                                          {Math.abs(
                                            obj.truncateDecimals(
                                              item?.profitAmount,
                                              2
                                            )
                                          )}
                                        </span>
                                      ) : (
                                        <span className="text-danger">
                                          (
                                          {Math.abs(
                                            obj.truncateDecimals(
                                              item?.loseAmount,
                                              2
                                            )
                                          )}
                                          )
                                        </span>
                                      )}
                                    </td>
                                  ) : (
                                    "Not Settled"
                                  )}
                                </td>
                              )}

                              {["active", "pending"]?.includes(
                                item?.status
                              ) && (
                                <td>
                                  <Button
                                    className="theme_light_btn"
                                    onClick={() =>
                                      setOpenToggle(
                                        item.matchBetId,
                                        item?.eventId,
                                        item?.userId,
                                        item?.marketId,
                                        "delete"
                                      )
                                    }
                                  >
                                    Delete
                                  </Button>
                                  <Button
                                    className="theme_light_btn"
                                    onClick={() =>
                                      setOpenToggle(
                                        item.matchBetId,
                                        item?.eventId,
                                        item?.userId,
                                        item?.marketId,
                                        "voided"
                                      )
                                    }
                                  >
                                    Voided
                                  </Button>{" "}
                                </td>
                              )}
                            </tr>
                            {item?.matchBetId == id && (
                              <tr
                                style={{ background: "#F3F2F1" }}
                                className="expand"
                              >
                                <td colSpan="9" className="expand_wrap ">
                                  <div
                                    style={{ background: "#F3F2F1" }}
                                    className="d-flex justify-content-center align-items-center"
                                  >
                                    <table className="table-commission table-commission-parlay w-75">
                                      <tbody>
                                        <tr className="header">
                                          <th>Betfair Event Id</th>
                                          <th>Market</th>
                                          <th>Selection</th>
                                          <th>Odds Req.</th>
                                          <th>Odds Matched</th>
                                          <th>Win/Loss</th>
                                        </tr>
                                        {item?.bets?.map((res) => {
                                          return (
                                            <tr
                                              id="txRow0"
                                              style={{
                                                display: "table-row",
                                                background: "white",
                                              }}
                                              className="even"
                                            >
                                              <td id="betID">{res?.eventId}</td>
                                              <td>
                                                {
                                                  obj.betCheckObj[
                                                    res?.eventType
                                                  ]
                                                }
                                                <span
                                                  style={{
                                                    background: "transparent",
                                                  }}
                                                  className="angle_unicode"
                                                >
                                                  ▸
                                                </span>
                                                <strong
                                                  style={{
                                                    background: "transparent",
                                                  }}
                                                >
                                                  {res?.matchName}
                                                </strong>
                                                <span
                                                  style={{
                                                    background: "transparent",
                                                  }}
                                                  className="angle_unicode"
                                                >
                                                  ▸
                                                </span>
                                                {res?.fancyName}
                                              </td>
                                              <td> {res?.runnerName}</td>
                                              <td>{res?.bhav}</td>
                                              <td>-</td>
                                              {res?.isDeclared ? (
                                                <td>
                                                  {" "}
                                                  {res?.result == "win" ? (
                                                    <span className="text-success">
                                                      {Math.abs(
                                                        obj.truncateDecimals(
                                                          res?.profitAmount,
                                                          2
                                                        )
                                                      )}
                                                    </span>
                                                  ) : (
                                                    <span className="text-danger">
                                                      (
                                                      {Math.abs(
                                                        obj.truncateDecimals(
                                                          res?.loseAmount,
                                                          2
                                                        )
                                                      )}
                                                      )
                                                    </span>
                                                  )}
                                                </td>
                                              ) : (
                                                <td>Not Settled</td>
                                              )}
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>{" "}
                                  </div>
                                </td>
                              </tr>
                            )}
                          </>
                        );
                      } else {
                        return (
                          <tr  className={
                            item?.betType == "lay" || item?.type == "No"
                              ? "lay-bg-row"
                              : item?.betType == "back" ||
                                item?.type == "Yes"
                              ? "back-bg-row"
                              : ""
                          }>
                            <td> {obj.betCheckObj[filter.type]}</td>
                            <td>{`${item?.matchName} -> ${
                              item?.betFaireType == "bookmaker"
                                ? "Bookmaker"
                                : !isEmpty(item?.market)
                                ? item?.market
                                : "Match Odds"
                            } -> ${item?.eventId}`}</td>
                            <td> {item?.clientName || "N/A"}</td>
                            <td
                             
                            >
                              {" "}
                              {item?.betType || "N/A"}
                            </td>
                            <td> {item?.teamName || "N/A"}</td>
                            <td> {item?.bhav || "N/A"}</td>
                            <td> {item?.amount || "N/A"}</td>
                            <td>
                              {" "}
                              {(item?.timeInserted &&
                                helpers.dateFormat(item.timeInserted)) ||
                                "N/A"}{" "}
                            </td>
                            <td>
                              {" "}
                              {(item?.ipAddress &&
                                item?.ipAddress.replace("::ffff:", "")) ||
                                "N/A"}{" "}
                            </td>
                            <td>
                              {" "}
                              {filter?.status == "suspend"
                                ? "-"
                                : item?.profitAmount || "N/A"}{" "}
                            </td>
                            {/* <td> {item?.loseAmount || "N/A"} </td> */}

                            {["active", "pending"]?.includes(item?.status) &&  filter?.status !== "suspend" && (
                              <td>
                                <Button
                                  className="theme_light_btn"
                                  onClick={() =>
                                    setOpenToggle(
                                      item.matchBetId,
                                      item?.eventId,
                                      item?.userId,
                                      item?.marketId,
                                      "delete"
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                                <Button
                                  className="theme_light_btn"
                                  onClick={() =>
                                    setOpenToggle(
                                      item.matchBetId,
                                      item?.eventId,
                                      item?.userId,
                                      item?.marketId,
                                      "voided"
                                    )
                                  }
                                >
                                  Voided
                                </Button>{" "}
                              </td>
                            )}
                          </tr>
                        );
                      }
                    })
                  ) : (
                    <tr>
                      <td colSpan={11}>
                        <span>You have no bets in this time period.</span>
                      </td>
                    </tr>
                  )}
                </Table>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <Modal show={open} onHide={setOpenToggle} className="change-status-modal">
        <Modal.Header closeButton>
          <Modal.Title className="modal-title-status h4">
            {startCase(getStatus)} Match
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="test-status border-0">
            <Form
              className="change-password-sec"
              onSubmit={handleSubmit1(onSubmit1)}
            >
              <Form.Group className=" mb-2">
                <h4 className="modal-title-status h4 text-center mb-3">
                  Are you want to {startCase(getStatus)}
                </h4>
              </Form.Group>
              {/* <Form.Group className=" mb-2">
                <Form.Control
                  type="password"
                  placeholder="Enter Password"
                  className={
                    errors1.password
                      ? " w-sm-50 m-auto is-invalid "
                      : "w-sm-50 m-auto"
                  }
                  {...register1("password", {
                    required: "Please enter password",
                  })}
                />
                {errors1.password && errors1.password.message && (
                  <label className="invalid-feedback text-left">
                    {errors1.password.message}
                  </label>
                )}
              </Form.Group> */}

              <div className="text-center mt-4">
                <Button type="submit" className="green-btn">
                  {isLoader ? "Loading..." : "Submit"}
                </Button>{" "}
                <Button onClick={setOpenToggle} className="green-btn">
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default LiveMatchBet;
